import DataS from "./data.s";

export default class EmployeeS extends DataS {
  getEmployees() {
    return this.get('/employees/all')
  }

  validationRh(data) {
    return this.post('/employees/valid-rh', data)
  }

  removeFile(col, employee) {
    return this.patch('/employees/files/remove', {col, employee})
  }

  getEmployee(id) {
    return this.get('/employees/' + id)
  }

  deleteAdmin(data) {
    return this.get('/employees/admins/delete/' + data)
  }

  importEmployees() {
    return this.get('/employees/import')
  }


  defineLastDiploma(EMPLOYEE_ID, id) {
    return this.patch('/employees/update/diploma', {id, EMPLOYEE_ID})
  }

  setPersonal({LASTNAME, FIRSTNAME, DENOMINATION, BIRTHPLACE, BIRTHDATE, GENDER, COUNTRY, EMPLOYEE_ID}) {
    return this.patch('/employees/update/personal', {
      LASTNAME,
      FIRSTNAME,
      DENOMINATION,
      BIRTHPLACE,
      BIRTHDATE,
      GENDER,
      COUNTRY,
      EMPLOYEE_ID
    })
  }

  setProfessional(
    {
      ACTIVITY_PRINCIPAL,
      ACCOUNT,
      LASTEMPLOYER,
      LASTJOB,
      LASTJOBEND,
      LASTJOBSTART,
      LASTJOBSTILL,
      EMPLOYEE_ID
    }
  ) {
    return this.patch('/employees/update/professional',
      {
        ACTIVITY_PRINCIPAL,
        EMPLOYEE_ID,
        ACCOUNT,
        LASTEMPLOYER,
        LASTJOB,
        LASTJOBEND,
        LASTJOBSTART,
        LASTJOBSTILL
      })
  }

  setFamilial(
    {
      CIVIL_STATUS,
      SPFIRSTNAME,
      SPBIRTHDATE,
      SPLASTNAME,
      SPEMAIL,
      SPNUMPHONE,
      CHILDNUM,
      EMERGNUM1,
      EMERGNAME1,
      EMPLOYEE_ID
    }
  ) {
    return this.patch('/employees/update/familial', {
      CIVIL_STATUS,
      SPFIRSTNAME,
      SPBIRTHDATE,
      SPLASTNAME,
      SPEMAIL,
      SPNUMPHONE,
      CHILDNUM,
      EMERGNUM1,
      EMERGNAME1,
      EMPLOYEE_ID
    })
  }

  setAddress({NUMPHONE, NUMPHONE2, EMAIL, TOWN, GMAIL_ACCOUNT, DISTRICT, PRECINCT, EMPLOYEE_ID}) {
    return this.patch('/employees/update/address', {
      NUMPHONE,
      NUMPHONE2,
      TOWN,
      DISTRICT,
      PRECINCT,
      EMAIL,
      GMAIL_ACCOUNT,
      EMPLOYEE_ID
    })
  }

  contactHim(DESIGNATION, EMPLOYEES) {
    return this.post('/employees/contact-him', {DESIGNATION, EMPLOYEES})
  }

  setUserActive(value) {
    return this.patch('/employees/update/active', value)
  }

  updateInfo(data) {
    return this.post('/employees', data)
  }
}
