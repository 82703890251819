import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import logo from '../../../../assets/img/logo/logo.png';

class SidebarHeader extends Component {
  render() {
    let {
      menuShadow,
    } = this.props;
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="flex items-center py-3 gap-3">
              <div><img src={logo} className='w-7 h-7' width={32} height={32} alt="logo" /></div>
              <h2 className="brand-text mb-0 text-xl font-semibold">GALIO</h2>
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames('shadow-bottom', {
            'd-none': menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
