import React from "react";
import { Link, withRouter } from "react-router-dom";
import navigationConfig from "../../../../../configs/navigationConfig";
import { FormattedMessage } from "react-intl";
import Cookies from "universal-cookie";
import cookieConstants from "../../../../../constants/cookie.c";

class SideMenuContent extends React.Component {
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: []
  };

  constructor(props) {
    super(props);

    this.parentArr = [];
  }

  initRender = parentArr => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false
    });
  };

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }
  }

  render() {
    let user = new Cookies().get(cookieConstants.FP_USER);
    let filteredMenu = navigationConfig.filter(item => user?.USERPRIVILEGE.split("-").includes(item.id.toString()));
    let filteredLinks = filteredMenu.map(e => ({ id: e.id, title: e.title, navLink: e.navLink }));

    if (filteredLinks.length === 0)
      this.props.history.push("/login");


    const menuItems = filteredMenu.map((item, id) => {
      const CustomAnchorTag = Link;
      const isActive = this.props.history.location.pathname === item.navLink
      return <li
        className={`${(isActive ? "hover:text-white" : "hover:text-blue-500 hover:bg-blue-100")} hover:pl-6 px-3 mt-1 rounded-lg duration-150 py-3 ${this.props.history.location.pathname===item.navLink?'bg-blue-600 shadow-lg text-white':''}`}
        key={item.id}
        onClick={e => {
          e.stopPropagation();
          this.props.handleActiveItem(item.navLink);
          if (this.props.deviceWidth <= 1200 && item.type === "item") this.props.toggleMenu();
        }}>
        <CustomAnchorTag
          to={item.filterBase ? item.filterBase : item.navLink ? item.navLink : ""}
          className="flex justify-start"
          onMouseEnter={() => {
            this.props.handleSidebarMouseEnter(item.id);
          }}
          onMouseLeave={() => {
            this.props.handleSidebarMouseEnter(item.id);
          }}
          key={item.id}>
          <div className="menu-text flex gap-3 items-center">
            {item.icon}
            <span className="menu-item menu-title">
                <FormattedMessage id={item.title} />
              </span>
          </div>
        </CustomAnchorTag>
      </li>;
    });
    return (
      <>
        {menuItems}
      </>);
  }
}

export default withRouter(SideMenuContent);
