import React from "react"
import logo from "../../assets/img/logo/logo.png"

class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner h-screen w-screen flex items-center justify-center text-center">
        <img className="fallback-logo animate-pulse" src={logo} alt="logo" />
      </div>
    )
  }
}

export default SpinnerComponent
