import React from "react";
import { Navbar } from "reactstrap";
import NavbarUser from "./NavbarUser";

const ThemeNavbar = props => {
  return (
    <>
      <Navbar
        className="p-3 bg-gradient-to-b from-slate-200 to-transparent sticky top-0 z-[100]">
        <div className="shadow-lg bg-white rounded px-6 py-3">
            <div
              className="flex justify-end items-center"
              id="navbar-mobile"
            >
              {props.horizontal ? (
                <div className="logo flex items-center">
                  <div className="brand-logo mr-50" />
                  <h2 className="text-primary brand-text mb-0">GALIO</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userImg={""}
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
              />
            </div>
        </div>
      </Navbar>
    </>
  );
};

export default ThemeNavbar;
