import React, { PureComponent } from 'react';
import Sidebar from './components/menu/vertical-menu/Sidebar';
import Navbar from './components/navbar/Navbar';
import Footer from './components/Footer';
import { withRouter } from 'react-router-dom';

class VerticalLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    sidebarState: false,
    layout: 'light',
    collapsedContent: false,
    sidebarHidden: false,
    currentLang: 'en',
    appOverlay: false,
    customizer: false,
    currRoute: this.props.location?.pathname,
  };
  collapsedPaths = [];
  mounted = false;
  updateWidth = () => {
    if (this.mounted) {
      this.setState(prevState => ({
        width: window.innerWidth,
      }));
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (window !== 'undefined')
        window.addEventListener('resize', this.updateWidth, false);
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      return null;
    }
  }

  handleCollapsedMenuPaths = item => {
    let collapsedPaths = this.collapsedPaths;
    if (!collapsedPaths.includes(item)) {
      collapsedPaths.push(item);
      this.collapsedPaths = collapsedPaths;
    }
  };

  toggleSidebarMenu = val => {
    this.setState({
      sidebarState: !this.state.sidebarState,
      collapsedContent: !this.state.collapsedContent,
    });
  };

  handleSidebarVisibility = () => {
    if (this.mounted) {
      if (window !== undefined) {
        window.addEventListener('resize', () => {
          if (this.state.sidebarHidden) {
            this.setState({
              sidebarHidden: !this.state.sidebarHidden,
            });
          }
        });
      }
      this.setState({
        sidebarHidden: !this.state.sidebarHidden,
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCurrentLanguage = lang => {
    this.setState({
      currentLang: lang,
    });
  };

  handleAppOverlay = value => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true,
      });
    } else if (value.length < 0 || value === '') {
      this.setState({
        appOverlay: false,
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false,
    });
  };

  render() {
    let sidebarProps = {
      collapsedMenuPaths: this.handleCollapsedMenuPaths,
      currentLang: this.state.currentLang,
      activeTheme: 'primary',
      collapsed: this.state.collapsedContent,
      deviceWidth: this.state.width,
    };
    let navbarProps = {
      toggleSidebarMenu: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarVisibility: this.handleSidebarVisibility,
      currentLang: this.state.currentLang,
      changeCurrentLang: this.handleCurrentLanguage,
      handleAppOverlay: this.handleAppOverlay,
      appOverlayState: this.state.appOverlay,
      navbarColor: 'default',
      navbarType: 'floating',
    };

    let footerProps = {
      footerType: 'static',
      hideScrollToTop: false,
    };

    return (
      <div className="flex h-screen flex-col">
        <div className="flex flex-1 h-full">
          <Sidebar {...sidebarProps} />
          <div
            className="flex-1 flex-col flex bg-gray-200 h-full overflow-y-auto"
            onClick={this.handleAppOverlayClick}
          >
            <Navbar {...navbarProps} />
            <div className="p-8 flex-1">{this.props.children}</div>
            <Footer {...footerProps} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VerticalLayout);
