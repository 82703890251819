const CookieC =  {
    FP_DATA:'FP_DATA',
    FP_USER:'FP_USER',
    FP_STATUS:'FP_STATUS' ,
    FP_VERSION: 'FP_VERSION',
    VERSION: '2.2.1',
    MEDIA: 'https://igoserver.myiuc.com/media/picture',
    TTL: 1800,
    URL: 'https://galioadmin.myiuc.com',
    // URL: 'http://localhost:3001',
    // SERVER: 'http://localhost:3002',
    SERVER: 'https://galioserver.myiuc.com',
    // SERVER: 'http://172.16.145.18--+3:3002',
}

export default CookieC